import React, { useRef, useState } from 'react'
import styles from './header.module.css'
import BlockText from '../block-text/block-text'
import { useOnClickOutside } from '../../hooks'

const Header = ({ onHideNav, onShowNav, showNav, siteTitle, _rawAboutBody }) => {
  const ref = useRef(null)
  const [isOpen, setIsInfoOpen] = useState(false)

  useOnClickOutside(ref, () => setIsInfoOpen(false))

  return (
    <header ref={ref}>
      <div className={styles.root}>
        <h1 onClick={() => setIsInfoOpen(!isOpen)}>{siteTitle}</h1>
      </div>
      {isOpen && (
        <section className={styles.section}>
          <BlockText blocks={_rawAboutBody} />
        </section>
      )}
    </header>
  )
}

export default Header
