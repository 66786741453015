import { graphql } from 'gatsby'
import React, { createContext, useContext, useMemo, useState } from 'react'
import EntityGrid from '../components/entity-grid/entity-grid'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  fragment AlbumFragment on SanityAlbum {
    id: _id
    images {
      asset {
        metadata {
          dimensions {
            width
            height
            aspectRatio
          }
          palette {
            dominant {
              background
            }
          }
        }
      }
      crop {
        _key
        _type
        top
        bottom
        left
        right
      }
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
      }
      alt
    }
    title
    _rawExcerpt
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      image {
        asset {
          main: fixed(toFormat: JPG, width: 1200) {
            src
          }
        }
      }
    }
    commissions: allSanityCategoryWithAlbums(filter: { _id: { eq: "commissions" } }) {
      nodes {
        albums {
          ...AlbumFragment
        }
      }
    }
    projects: allSanityCategoryWithAlbums(filter: { _id: { eq: "projects" } }) {
      nodes {
        albums {
          ...AlbumFragment
        }
      }
    }
  }
`

export const ActivePaneContext = createContext()
export const CarouselContext = createContext()

export function useCarousel() {
  return useContext(CarouselContext)
}

export function useActivePane() {
  return useContext(ActivePaneContext)
}

export function ActivePaneStateProvider({ children }) {
  const [activePane, setActivePane] = useState('left')

  const value = useMemo(() => [activePane, setActivePane], [activePane])

  return <ActivePaneContext.Provider value={value}>{children}</ActivePaneContext.Provider>
}

export function CarouselStateProvider({ children }) {
  const carouselState = useState(false)
  return (
    <CarouselContext.Provider value={carouselState}>
      {children(carouselState)}
    </CarouselContext.Provider>
  )
}

const IndexPage = props => {
  const { data = {}, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = data.site
  const commissionNodes = data.commissions.nodes[0].albums
  const projectNodes = data.projects.nodes[0].albums

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <ActivePaneStateProvider>
      <Layout>
        <SEO
          key="SEO"
          image={site.image.asset.main.src}
          title={site.title}
          description={site.description}
          keywords={site.keywords}
        />
        <EntityGrid key="Commissions" title="Commissions" side="right" nodes={commissionNodes} />
        <EntityGrid key="Projects" title="Projects" side="left" nodes={projectNodes} />
      </Layout>
    </ActivePaneStateProvider>
  )
}

export default IndexPage
