import delve from 'dlv'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { buildImageObj, cn } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import { useCarousel } from '../../pages'
import BlockText from '../block-text/block-text'
import styles from './entity.module.css'
import { useLocationState } from 'use-location-state'
import { FiX } from 'react-icons/fi'

const Source = ({ width, url }) => <source media={`(max-width: ${width}px)`} srcSet={url} />

function Entity (props) {
  const [isOpen, setIsOpen] = useLocationState(`entity-${props.id}`, false)
  const [displayExcerpt, setDisplayExcerpt] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [currentPreviewImage, setCurrentPreviewImage] = useState(currentImage)
  const [, setIsCarouselOpen] = useCarousel()

  const next = useCallback(() => {
    setCurrentImage(currentImage === props.images.length - 1 ? 0 : currentImage + 1)
  }, [currentImage, setCurrentImage, props.images.length])

  const prev = useCallback(() => {
    setCurrentImage(currentImage === 0 ? props.images.length - 1 : currentImage - 1)
  }, [currentImage, setCurrentImage, props.images.length])

  const openCarousel = () => {
    setIsOpen(true, { method: 'push' })
    setIsCarouselOpen(true)
  }
  const closeCarousel = () => {
    setCurrentPreviewImage(currentImage)
    setIsCarouselOpen(false)
    window.history.back()
  }

  useEffect(() => {
    if (isOpen) setIsCarouselOpen(true)
  }, [isOpen, setIsCarouselOpen])

  const image = props.images[currentPreviewImage]

  useEffect(() => {
    if (!isOpen) {
      return
    }

    function doWork (e) {
      if (e.key === 'Escape' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        closeCarousel()
      }
      if (e.key === 'ArrowLeft') {
        prev()
      }
      if (e.key === 'ArrowRight') {
        next()
      }
    }

    window.addEventListener('keydown', doWork)

    return () => window.removeEventListener('keydown', doWork)
  }, [isOpen, prev, next])

  const handleImageClick = useCallback(
    function handleImageClick (e) {
      e.persist()
      e.nativeEvent.stopImmediatePropagation()
      e.stopPropagation()
      const clickTarget = e.target
      const clickTargetWidth = clickTarget.offsetWidth
      const xCoordInClickTarget = e.clientX - clickTarget.getBoundingClientRect().left
      const clickedOnLeftSide = clickTargetWidth / 2 > xCoordInClickTarget
      if (clickedOnLeftSide) {
        prev()
      } else {
        next()
      }
    },
    [prev, next]
  )

  const src = imageUrlFor(buildImageObj(image))
    .width(1200)
    .url()

  const aspectRatio = image.asset.metadata.dimensions.aspectRatio ||
    image.asset.metadata.dimensions.width / image.asset.metadata.dimensions.height

  const imageSrcSet = [480, 720, 1280, 1920, 2560, 3840].map(i => {
    return [
      i,
      imageUrlFor(buildImageObj(image))
        .width(i)
        .quality(90)
        .url()
    ]
  })

  return (
    <>
      <div className={styles.leadMediaThumb} data-entity-id={props.id} style={{
        '--aspect-ratio': aspectRatio
      }}>
        <picture
          style={{
            backgroundColor: delve(image, 'asset.metadata.palette.dominant.background', '#eee'),
            aspectRatio: aspectRatio ? 'var(--aspect-ratio)' : null
          }}
          className={cn(
            props.className,
            styles.leadMediaImage,
            props.side === 'right' && styles.leadMediaImageAlignRight
          )}
        >
          {imageSrcSet.map(([i, url]) => (
            <Source
              key={i}
              width={i}
              url={url}
            />
          ))}
          <img
            loading='lazy'
            src={src}
            alt={image.alt}
            style={{
              aspectRatio: aspectRatio ? 'var(--aspect-ratio)' : null
            }}
            className={cn(
              props.className,
              styles.leadMediaImage,
              props.side === 'right' && styles.leadMediaImageAlignRight
            )}
            width={delve(image, 'asset.metadata.dimensions.width', 400)}
            height={delve(image, 'asset.metadata.dimensions.height', 400)}
            onClick={openCarousel}
          />
        </picture>
      </div>
      <h3
        className={cn(
          styles.title,
          props.side === 'right' && styles.titleAlignRight,
          !props._rawExcerpt && styles.titleWithoutExcerpt
        )}
        onClick={() => setDisplayExcerpt(!displayExcerpt)}
      >
        {props.title}
      </h3>
      {props._rawExcerpt && displayExcerpt && (
        <div className={cn(styles.excerpt, props.side === 'right' && styles.titleAlignRight)}>
          <BlockText blocks={props._rawExcerpt} />
        </div>
      )}
      {isOpen && (
        <Carousel
          images={props.images}
          currentImage={currentImage}
          closeCarousel={closeCarousel}
          title={props.title}
          isOpen={isOpen}
          handleImageClick={handleImageClick}
        />
      )}
    </>
  )
}

function Carousel ({ images, currentImage, handleImageClick, closeCarousel, title }) {
  return (
    <div className={styles.carousel}>
      <div className={styles.closeIcon} onClick={closeCarousel}>
        <FiX />
      </div>
      {images.map((image, key) => (
        <div
          className={cn(
            styles.carouselItemWrap,
            key === currentImage && styles.carouselItemWrapIsSelected
          )}
          key={key}
          onClick={closeCarousel}
        >
          <div className={styles.carouselItemOverlay} onClick={handleImageClick}>
            {(key === currentImage ||
              key === currentImage + 1) && (
              <picture
                style={{
                  backgroundColor: delve(
                    image,
                    'asset.metadata.palette.dominant.background',
                    '#eee'
                  )
                }}
              >
                {[480, 720, 1280, 1920, 2560, 3840].map(i => (
                  <Source
                    key={i}
                    width={i}
                    url={imageUrlFor(buildImageObj(image))
                      .width(i)
                      .quality(93)
                      .url()}
                  />
                ))}
                <img
                  src={imageUrlFor(buildImageObj(image))
                    .width(1440)
                    .quality(93)
                    .url()}
                  alt={image.alt}
                  width={delve(image, 'asset.metadata.dimensions.width', 400)}
                  height={delve(image, 'asset.metadata.dimensions.height', 400)}
                  data-height={delve(image, 'asset.metadata.dimensions.height', 400)}
                  className={cn(
                    styles.carouselImage,
                    delve(image, 'asset.metadata.dimensions.height', 400) <
                      window.innerHeight - 80
                      ? styles.carouselImageClamped
                      : null
                  )}
                />
              </picture>
            )}
          </div>
        </div>
      ))}
      <div className={styles.carouselMeta}>
        <h3>{title}</h3>
        <h3>
          {currentImage + 1} / {images.length}
        </h3>
      </div>
    </div>
  )
}

export default memo(Entity)
