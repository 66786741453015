import React from 'react'
import Header from './header/header'

import '../styles/layout.css'

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle, _rawAboutBody }) => (
  <>
    <Header
      siteTitle={siteTitle}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
      _rawAboutBody={_rawAboutBody}
    />
    {children}
  </>
)

export default Layout
