import React, { useRef, useEffect, useState, memo } from 'react'
import Entity from '../entity/entity'

import styles from './entity-grid.module.css'
import { useActivePane, CarouselStateProvider } from '../../pages'
import { cn } from '../../lib/helpers'
import { useOnClickOutside } from '../../hooks'
import smoothscroll from 'smoothscroll-polyfill'

// kick off the polyfill!
if (typeof window !== 'undefined') {
  smoothscroll.polyfill()
}

function EntityGrid ({ title, side, nodes = [] }) {
  const navRef = useRef(null)
  const sectionRef = useRef(null)
  const [selected, setSelected] = useState('')
  const [activePane, setActivePane] = useActivePane()
  const [isNavOpen, setNavOpen] = useState(false)

  useOnClickOutside(navRef, () => isNavOpen && setNavOpen(false))

  function handleMenuItemClick (event) {
    if (isNavOpen) {
      setNavOpen(false)
      setSelected({ target: event.target.getAttribute('data-entity-id') })
      setActivePane(side)
    }
  }

  useEffect(() => {
    if (!selected || !selected.target) {
      return
    }
    try {
      const wanted = sectionRef.current.querySelector(`[data-entity-id="${selected.target}"]`)
      if (wanted) {
        sectionRef.current.scrollTo({
          top: wanted.getBoundingClientRect().top + sectionRef.current.scrollTop - 20,
          behavior: 'smooth'
        })
      }
    } catch (e) {}
  }, [selected])

  return (
    <CarouselStateProvider>
      {([isCarouselOpen]) => (
        <>
          <section
            ref={sectionRef}
            key={side}
            className={cn(
              styles.root,
              activePane === side ? styles.active : null,
              styles[side],
              isCarouselOpen ? styles.whenCarouselIsOpen : null
            )}
            onClick={() => activePane !== side && setActivePane(side)}
          >
            {nodes.map(node => (
              <Entity
                key={node.id}
                {...node}
                side={side}
                className={selected === node.id ? styles.selected : null}
              />
            ))}
          </section>
          <nav
            className={cn(styles.menu, styles[side])}
            onMouseOver={() => !isNavOpen && setNavOpen(true)}
            ref={navRef}
          >
            <h2 className={styles.headline}>{title}</h2>
            {isNavOpen &&
              nodes.map((node, key) => (
                <h2
                  className={styles.menuItem}
                  key={node.id}
                  data-entity-id={node.id}
                  onClick={handleMenuItemClick}
                >
                  {node.title}
                </h2>
              ))}
          </nav>
        </>
      )}
    </CarouselStateProvider>
  )
}

export default memo(EntityGrid)
